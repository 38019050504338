<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Consider the conversion of 2-naphthol and 1-bromobutane into to 2-butoxynaphthalene via the
        Williamson ether synthesis. Drag each of the steps listed below into the correct order for
        this experiment.
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="First Step"
        append-text="Final Step"
      />
    </v-form>
  </div>
</template>

<script>
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput.vue';

export default {
  name: 'ChemUCI51LBA4Q6',
  components: {
    DragToRankInput,
    seededShuffle,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        'Heat under reflux for 20 minutes, until all the base dissolves.',
        'Add 1-bromobutane and reflux for an additional hour.',
        'Collect the precipitate by vacuum filtration on a Buchner funnel.',
        'Dissolve 2-naphthol in ethanol in a round bottom flask.',
        'Pour the contents of the round bottom flask into a beaker of ice.',
        'Rinse the product with cold water and dry it on the filter.',
        'Add sodium hydroxide pellets and boiling chips. Attach a condenser.',
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
